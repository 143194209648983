// import React, { useEffect, useState } from 'react';
import React from 'react';
import { Parallax } from 'react-parallax';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import * as BS from 'react-bootstrap';

import ReactGA from 'react-ga';
const TRACKING_ID = "G-CCESECEJZD"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const HeaderNavbar = () => {
  return (
    <BS.Navbar id="home" bg="light" expand="lg" sticky="top">
      <BS.Container>
        <BS.Navbar.Brand href="#home">
          <BS.Image src="/assets/images/logo.png" alt="sakarioka banner" className="navbar__logo"></BS.Image>
        </BS.Navbar.Brand>
        <BS.Navbar.Toggle aria-controls="navbar__nav" />
        <BS.Navbar.Collapse id="navbar__nav">
          <BS.Nav className="ms-auto">
            <BS.Nav.Link href="#home">Home</BS.Nav.Link>
            <BS.Nav.Link href="#about">About</BS.Nav.Link>
            <BS.Nav.Link href="#product">Product</BS.Nav.Link>
            <BS.Nav.Link href="#portfolio">Portfolio</BS.Nav.Link>
            <BS.Nav.Link href="javascript:alert('Undermaintenance.')">Blog</BS.Nav.Link>
          </BS.Nav>
        </BS.Navbar.Collapse>
      </BS.Container>
    </BS.Navbar>
  );
};

const App = () => {

  // const [setting, setSetting] = useState([])

  // useEffect(() => {
  //   fetch(`http://localhost:8000/setting`)
  //   .then((response) => response.json())
  //   .then((actualData) => {
  //     setSetting(actualData)
  //   });
  // }, [])

  return (
    <>
      <div className="App">
        <TawkMessengerReact
          propertyId="55d408342e11383646863083"
          widgetId="default"/>
      </div>
      <HeaderNavbar></HeaderNavbar>
      <BS.Image src="/assets/images/banner-20220711.png" className="w-100"></BS.Image>
      <BS.Container id="about">
        <div className="py-5 text-center">
          <h1 className="h3 mb-4">Build Your Own System</h1>
          <p>Optimize your business! Growing your business doesn't always mean getting new customers!</p>
          <p>It can also mean selling more to your existing customers by update your business plan or analyst your business.</p>
          
        </div>
      </BS.Container>
      <hr/>
      <BS.Container id="product">
        <div className="py-5 text-center">
          <BS.Row>
            <BS.Col sm={6} className="mb-4">
              <h3>Website</h3>
              <BS.ListGroup>
                <BS.ListGroup.Item>Custom Simple Design</BS.ListGroup.Item>
                <BS.ListGroup.Item>1 Year Hosting Server</BS.ListGroup.Item>
                <BS.ListGroup.Item>All-in-one CMS</BS.ListGroup.Item>
                <BS.ListGroup.Item>Monitoring &amp; Reporting</BS.ListGroup.Item>
                <BS.ListGroup.Item>Up to 3 Months Free Maintenance</BS.ListGroup.Item>
                <BS.ListGroup.Item>PHP, Laravel, CI, Bootstrap, MySQL</BS.ListGroup.Item>
                <BS.ListGroup.Item>From Rp1.200.000</BS.ListGroup.Item>
              </BS.ListGroup>
            </BS.Col>
            <BS.Col sm={6} className="mb-4">
              <h3>Custom</h3>
              <BS.ListGroup>
                <BS.ListGroup.Item>Custom Design</BS.ListGroup.Item>
                <BS.ListGroup.Item>1 Year Hosting Server</BS.ListGroup.Item>
                <BS.ListGroup.Item>Custom CMS</BS.ListGroup.Item>
                <BS.ListGroup.Item>Monitoring &amp; Reporting</BS.ListGroup.Item>
                <BS.ListGroup.Item>Up to 1 Year Free Maintenance</BS.ListGroup.Item>
                <BS.ListGroup.Item>Depend on request &#40;eg. React, Node, Go&#41;</BS.ListGroup.Item>
                <BS.ListGroup.Item>From Rp3.000.000</BS.ListGroup.Item>
              </BS.ListGroup>
            </BS.Col>
          </BS.Row>
        </div>
      </BS.Container>
      <div className="pb-5"></div>
      <Parallax blur={0} bgImage="/assets/images/parallax.jpg" bgImageAlt="pa rallax" strength={200}>
        <BS.Container id="portfolio" className="portfolio__parallax py-5">
          <h2 className="text-center mb-4">Portfolio</h2>
          <BS.Row>
            <BS.Col sm={6} lg={{ span: 4 }} className="mb-4">
              <a href="https://peramatour.com" rel="nofollow noreferrer noopener" target="_blank">
                <BS.Image src="/assets/images/portfolio-perama.png" alt="portfolio peramatour" className="w-100"></BS.Image>
                <div className="text-center mt-2 small">Perama Tour</div>
              </a>
            </BS.Col>
            <BS.Col sm={6} lg={{ span: 4 }} className="mb-4">
              <a href="https://lakupon.com" rel="nofollow noreferrer noopener" target="_blank">
                <BS.Image src="/assets/images/portfolio-lakupon.png" alt="portfolio lakupon" className="w-100"></BS.Image>
                <div className="text-center mt-2 small">Lakupon</div>
              </a>
            </BS.Col>
            <BS.Col sm={6} lg={{ span: 4 }} className="mb-4">
              <a href="https://www.sequisam.co.id/" rel="nofollow noreferrer noopener" target="_blank">
                <BS.Image src="/assets/images/portfolio-sequis.png" alt="portfolio sequis" className="w-100"></BS.Image>
                <div className="text-center mt-2 small">SequisAM</div>
              </a>
            </BS.Col>
            <BS.Col sm={6} lg={{ span: 4 }} className="mb-4">
              <a href="http://www.balisecretsdive.com/" rel="nofollow noreferrer noopener" target="_blank">
                <BS.Image src="/assets/images/portfolio-bali-secret-dive.png" alt="portfolio bali secret dive" className="w-100"></BS.Image>
                <div className="text-center mt-2 small">Bali Secret Dive</div>
              </a>
            </BS.Col>
            <BS.Col sm={6} lg={{ span: 4 }} className="mb-4">
              <a href="https://cookedid.com/" rel="nofollow noreferrer noopener" target="_blank">
                <BS.Image src="/assets/images/portfolio-cooked.png" alt="portfolio cooked id" className="w-100"></BS.Image>
                <div className="text-center mt-2 small">CookedID</div>
              </a>
            </BS.Col>
            <BS.Col sm={6} lg={{ span: 4 }} className="mb-4">
              <a href="https://ringkasan.id/" rel="nofollow noreferrer noopener" target="_blank">
                <BS.Image src="/assets/images/portfolio-ringkasan.png" alt="portfolio ringkasan id" className="w-100"></BS.Image>
                <div className="text-center mt-2 small">Ringkasan</div>
              </a>
            </BS.Col>
            <BS.Col sm={6} lg={{ span: 4, offset: 2 }} className="mb-4">
              <a href="https://cispyedu.com/" rel="nofollow noreferrer noopener" target="_blank">
                <BS.Image src="/assets/images/portfolio-cispyedu.png" alt="portfolio cispy edu" className="w-100"></BS.Image>
                <div className="text-center mt-2 small">Cispy Edu</div>
              </a>
            </BS.Col>
            <BS.Col sm={6} lg={{ span: 4 }} className="mb-4">
              <a href="https://ourwedding.sakarioka.com/" rel="nofollow noreferrer noopener" target="_blank">
                <BS.Image src="/assets/images/portfolio-ourwedding.png" alt="portfolio our wedding" className="w-100"></BS.Image>
                <div className="text-center mt-2 small">Our Wedding</div>
              </a>
            </BS.Col>
          </BS.Row>
        </BS.Container>
      </Parallax>
        <BS.Container id="footer">
          <div className="d-flex justify-content-center small" style={{wordSpacing: "4px", padding: "1rem"}}>
            <div>Made with &hearts; from Jakarta. Copyright &copy; {new Date().getFullYear()} Sakarioka.com</div>
          </div>
        </BS.Container>
    </>
  );
}

export default App;
